<template>
  <div class="container">

    <div class="row justify-content-center align-items-center mt-5 mb-5" style="min-height: calc(100vh - 6rem)">
      <div class="col-12 col-sm" style="max-width: 600px">

        <div class="mb-3 text-center">
          <h5 class=""> กรุณาชำระเงินภายในเวลา {{ minutes }}:{{ seconds }} นาที</h5>
          <div class=""> ใช้แอพพลิเคชั่นธนาคารสเเกน QR Code นี้ เพื่อชำระเงิน รองรับทุกธนาคาร</div>
        </div>


        <div class="card mb-5">

          <div class="card-header text-center" style="background-color: #153C67">
            <img class="img-fluid" style="max-height: 80px"
                 src="../assets/image/Thai_QR_Payment_Logo-01.jpg"/>
          </div>

          <div class="card-body">


            <div class="text-center mb-3">


              <img class="img-fluid" style="max-height: 40px" src="../assets/image/PromptPay2.png"/>


              <div style="position: relative">
                <qrcode :value="qr"
                        :options="{ width: 250, errorCorrectionLevel: 'H' , quality : 1 }"></qrcode>
              </div>

            </div>


            <div class="row">
              <div class="col">
                บริษัท สยามสเเควร์ เทคโนโลยี (ประเทศไทย) จำกัด
              </div>

              <div class="col-auto">
                <div class="col-auto"> {{ item.price * qty | number }} บาท</div>
              </div>
            </div>


          </div>
        </div>


        <div class="mb-3 text-center">
          กรุณาอย่าปิดหน้านี้ เมื่อชำระเงินสำเร็จ ระบบจะเปลี่ยนหน้าอัตโนมัติ
        </div>

        <div class="text-center" @click="howToPayment">
          วิธีชำระเงินด้วย QR Code
        </div>


      </div>
    </div>


  </div>
</template>

<script>

import Qrcode from "@chenfengyuan/vue-qrcode/src";
import {mapState} from 'vuex';
import {
  CHECK_TRANSACTION_STATUS,
} from "../store/actions.type";
import store from '../store';

export default {
  name: 'Home',
  components: {Qrcode},
  computed: {
    ...mapState({
      qr: state => state.payment.qr,
      transactionId: state => state.payment.transactionId,
      item: state => state.payment.itemInfo,
      user: (state) => state.payment.userInfo,
      qty: state => state.payment.qty,
    }),
  },
  async created() {
    if (this.transactionId) {
      let response = await store.dispatch(CHECK_TRANSACTION_STATUS, {
        transactionId: this.transactionId,
        phone: this.user.phone
      });
      this.exp = response.result.exp;

      let countDownDate = new Date();
      countDownDate.setMinutes(countDownDate.getMinutes() + 10);

      let countdown = setInterval(async function () {
        let now = new Date().getTime();
        let distance = countDownDate - now;

        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.minutes = this.minutes < 10 ? "0" + this.minutes : this.minutes;
        this.seconds = this.seconds < 10 ? "0" + this.seconds : this.seconds;

        if (distance < 0) {
          clearInterval(countdown);
          clearInterval(check);
          await this.$router.push('/message?message=expired')
        }
      }.bind(this), 1000);


      let check = setInterval(async function () {
        let {status, result} = await store.dispatch(CHECK_TRANSACTION_STATUS, {
          transactionId: this.transactionId,
          phone: this.user.phone
        });
        if (result.status === 'PAID') {
          store.state.payment.subTxn = result.sub_txn || [];
          store.state.payment.totalPrice = result.total_price || 0;

          clearInterval(countdown);
          clearInterval(check);
          await this.$router.push('/success');
        }
      }.bind(this), 10000);

      // handle browser back
      window.onpopstate = function (event) {
        this.$router.push('/payment')
      }.bind(this);

    } else {
      await this.$router.push('/message');
    }
  },
  data() {
    return {
      form: {},
      errorMessage: {},
      exp: null,
      minutes: "00",
      seconds: "00"
    }
  },
  methods: {
    howToPayment() {
      this.$showPanel({
        component: "howtopayment",
        openOn: 'right',
        width: '100%'
      });
    }
  }
}
</script>
